/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import "@fontsource/hind/400.css"
import "@fontsource/hind/700.css"
import "@fontsource/poppins/700.css"
import "./src/styles/global.css"

export const onRouteUpdate = ({ location }) => {
  // Check if there's a query string in the URL
  if (location.search && location.search.includes("?btn_text=")) {
    const urlWithoutQuery = location.pathname
    // Use window.location.replace for a 301-like redirect (no browser history)
    window.location.replace(urlWithoutQuery)
  }
}
